.sidebar .nav-item .nav-link:hover {
    background: #0c54a1;
}

.page-item.active .page-link {
    background-color: #004289;
    border-color: #004289;
}
.page-link {
    color: #004289;
}

.btn-outline-blanchard {
    color: #d80e21;
    border-color: #d80e21;
    &:hover {
        color: white;
        background-color: #d80e21;
    }
    &:focus {
        box-shadow: 0 0 0 0.18rem rgb(216 14 33 / 25%);
    }
}
.btn-blanchard {
    background-color: #d80e21;
    border-color: #d80e21;
    color: white;
    &:hover {
        color: white;
        background-color: #f11b30;
    }
    &:focus {
        box-shadow: 0 0 0 0.18rem rgb(216 14 33 / 25%);
    }
}
.btn-blanchard-blue {
    color: white;
    background: #004289;
    transition: all ease 150ms;
    &:hover {
        background: #0c54a1;
        color: white;
    }
}

.table td,
.table th {
    @extend .px-2;
}
